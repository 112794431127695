export class Variants {
	public type:string;
	public name:string;
	public data:any={}
	public static partitionSize:number=1000000;

	constructor(name:string,type:string="23andMe") {
		this.name=name;
		this.type=type;
	}

}
