const color={
  "gneg":"rgba(230,230,230,1)",
  "gpos25":"rgba(191,191,191,1)",
  "gpos50":"rgba(127,127,127,1)",
  "gpos75":"rgba(64,64,64,1)",
  "gpos100":"rgba(0,0,0,1)",
  "gpos":"rgba(200,180,150,1)",
  "acen":"rgba(200,127,0,1)",
  "gvar":"rgba(127,127,127,1)",
  "stalk":"rgba(100,100,100,1)"
}
const strokeStyle="rgba(60,60,60,0.2)"
const lineWidth=0.1
export class wbIdiogram {



  constructor() {
  }

  static drawVerticalIdiogram(ctx:CanvasRenderingContext2D,idiogram:any,beg:number,end:number,x:number,y:number,width:number,height:number) {
      ctx.rotate(90* Math.PI / 180);
      wbIdiogram.drawIdiogram(ctx,idiogram,beg,end,y,-x-width,height,width);
      ctx.setTransform(1, 0, 0, 1, 0, 0);
  }

  static drawIdiogram(ctx:CanvasRenderingContext2D,idiogram:any,beg:number,end:number,x:number,y:number,width:number,height:number) {

    let centro=0;
    for(let i in idiogram) {
      let band:any=idiogram[i];

      if(band.color=="acen") { centro++ }
      let bandColor:string=color[band.color];
      if(!bandColor) { bandColor="#990000";console.log(band.color)}
      ctx.fillStyle =bandColor;
      ctx.strokeStyle =strokeStyle;
      ctx.lineWidth=lineWidth;
      if(band.beg*1>end || band.end*1<beg) {
        //band out of scope
      } else {
        let bandStart=band.beg*1;
        if(bandStart<beg) { bandStart=beg; }
        let bandEnd=band.end*1;
        if(bandEnd>end) { bandEnd=end; }
        let bandStartPxl=x+(bandStart-beg)/(end-beg)*width;
        let bandEndPxl=x+(bandEnd-beg)/(end-beg)*width;
        let drawChrShape:boolean=(band.end*1-band.beg*1)<(end-beg)*2;
        if(band.beg*1==0 && drawChrShape) {
          //start
          let oribandStartPxl=x+(band.beg*1-beg)/(end-beg)*width;
          let oribandEndPxl=x+(band.end*1-beg)/(end-beg)*width;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(oribandEndPxl,y+height);
          ctx.quadraticCurveTo(oribandStartPxl,y+height,oribandStartPxl,y+height/2);
          ctx.quadraticCurveTo(oribandStartPxl,y,oribandEndPxl,y);
          ctx.lineTo(oribandEndPxl,y+height);
          ctx.stroke();
          ctx.clip();
          ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
          ctx.restore();
        } else if(Number(i)==idiogram.length-1 && drawChrShape) {
          //end
          let oribandStartPxl=x+(band.beg*1-beg)/(end-beg)*width;
          let oribandEndPxl=x+(band.end*1-beg)/(end-beg)*width;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(oribandStartPxl,y+height);
          ctx.quadraticCurveTo(oribandEndPxl,y+height,oribandEndPxl,y+height/2);
          ctx.quadraticCurveTo(oribandEndPxl,y,oribandStartPxl,y);
          ctx.lineTo(oribandStartPxl,y+height);
          ctx.stroke();
          ctx.clip();
          ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
          ctx.restore();
        } else if(band.color=="acen" && drawChrShape) {
          //centromeer

          ctx.save();
          ctx.beginPath();
          let oribandStartPxl=x+(band.beg*1-beg)/(end-beg)*width;
          let oribandEndPxl=x+(band.end*1-beg)/(end-beg)*width;
          let bandwidth=oribandEndPxl-oribandStartPxl;
          if(centro==1) {
            //band before centro
            ctx.moveTo(oribandStartPxl,y+height);
            ctx.quadraticCurveTo(oribandEndPxl,y+height,oribandEndPxl,y+height/2);
            ctx.quadraticCurveTo(oribandEndPxl,y,oribandStartPxl,y);
            ctx.lineTo(oribandStartPxl,y+height);
          } else {
            //band after centro
            ctx.moveTo(oribandEndPxl,y+height);
            ctx.quadraticCurveTo(oribandStartPxl,y+height,oribandStartPxl,y+height/2);
            ctx.quadraticCurveTo(oribandStartPxl,y,oribandEndPxl,y);
            ctx.lineTo(oribandEndPxl,y+height);
          }


          ctx.stroke();
          ctx.clip();
          ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
          ctx.restore();

        } else {
          ctx.save();
          ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
          ctx.beginPath();
          ctx.rect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
          ctx.stroke();
          ctx.restore();
          //ctx.fill();
        }
        if(bandEndPxl-bandStartPxl>80) {

          ctx.font = "8px Helvetica";
          ctx.textAlign="center";
          ctx.fillStyle ="#999999";
          ctx.fillText(band.loc,bandStartPxl+(bandEndPxl-bandStartPxl)/2,y+height/2+1);
          ctx.fillStyle =bandColor;
        }

        //console.log(bandStartPxl,y,bandEndPxl-bandStartPxl,height,bandColor);
      }

    }
  }
}
