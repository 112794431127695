import * as Hammer from 'hammerjs';
import { Component, OnInit,Input,ViewChild,ElementRef } from '@angular/core';
import { BrowserService } from '../../../core/services/browser.service'
import { wbIdiogram } from '../../../core/data/graphics/wb-idiogram'
import { NgZone } from '@angular/core'
import { Chromosome } from '../../../core/data/chromosome'
const lineColor="rgba(26,115,232,0.4)";
const currentRegionFillColor="rgba(26,115,232,0.5)";
const gridLineWidth=0.5;

@Component({
  selector: 'app-wb-chromosome',
  templateUrl: './wb-chromosome.component.html',
  styleUrls: ['./wb-chromosome.component.scss']
})
export class WbChromosomeComponent implements OnInit {

	@ViewChild('c') c:ElementRef ;
	@Input() chromosome:Chromosome;
  @Input() height=40;
  @Input() width=400;
	private zone:NgZone;
  public chrLength:number=1;
  public subscription;
  public ctx:CanvasRenderingContext2D;
  private deltaX:number=0;
	public maxwidth:number=400;
  constructor(private engine:BrowserService) { }

  ngOnInit() {
		this.ctx=(<HTMLCanvasElement>this.c.nativeElement).getContext('2d');
		(<HTMLCanvasElement>this.c.nativeElement).style.width=this.width+"px";
		(<HTMLCanvasElement>this.c.nativeElement).width=this.width;
		(<HTMLCanvasElement>this.c.nativeElement).height=this.height;
		this.subscription = this.engine.mover.subscribe(item => this.reDraw());

		var hammertime = new Hammer(this.c.nativeElement);
		//hammertime.get('swipe').set({ direction: Hammer.DIRECTION_ALL });
		hammertime.get('pan').set({ direction: Hammer.DIRECTION_ALL });
		hammertime.get('tap').set({ enable: true ,posThreshold:1});

		hammertime.on('pan', (ev) => {
			 //console.log(ev);

			 let delta=(ev.deltaX-this.deltaX)*this.engine.getTrackWidthPixels()/this.width*(this.chrLength)/(this.engine.end-this.engine.beg);
				 //this.setToolTip("panned "+delta+"px",ev.center.x+35,ev.center.y+70);
					this.engine.move(-delta);
					this.deltaX=ev.deltaX;

			 //console.log(ev);
			 if(ev.isFinal) {
				 this.deltaX=0;
			 }
			 //setTimeout(() => { this.fadeOutMove(ev.velocityX*15); },10);
		 });
		 hammertime.on('tap', (ev) => {
				if(ev.pointerType=="mouse") {
					this.engine.centerOn(Math.round(ev.srcEvent.offsetX/this.width*this.chrLength));
				}
		});
  }

	public reDraw() {
		if(this.chromosome) {
      this.width=this.maxwidth*(this.chromosome.length*1)/(this.engine.chromosomes[this.engine.currentGenome][0].length*1);
      (<HTMLCanvasElement>this.c.nativeElement).style.width=this.width+"px";
      (<HTMLCanvasElement>this.c.nativeElement).width=this.width;
      (<HTMLCanvasElement>this.c.nativeElement).height=this.height;
      this.chrLength=this.chromosome.length*1;
      this.drawData();
			this.drawPos();
		}
  }

  public drawData() {
    wbIdiogram.drawIdiogram(this.ctx,this.chromosome.idiogram,1,this.chrLength,0,10,(<HTMLCanvasElement>this.c.nativeElement).width,(<HTMLCanvasElement>this.c.nativeElement).height-20);
  }

  public drawPos() {
    let w=(<HTMLCanvasElement>this.c.nativeElement).width;
    let h=(<HTMLCanvasElement>this.c.nativeElement).height;
    let partSize=this.engine.getPartitionSize();
    let ppb:number=w/this.chrLength;
    this.ctx.strokeStyle=lineColor;
    this.ctx.fillStyle=currentRegionFillColor;
    this.ctx.lineWidth=gridLineWidth;
      this.ctx.beginPath();
      let bandStartPxl=ppb*(this.engine.beg*1);
      let bandEndPxl=ppb*(this.engine.end*1);
			let width=bandEndPxl-bandStartPxl;
			if(width<this.height/8) {

				bandStartPxl=bandStartPxl-(this.height/8-width)
				bandEndPxl=bandEndPxl+(this.height/8-width)
				width=this.height/8
			}
			this.ctx.fillStyle=currentRegionFillColor;
      this.roundRect( this.ctx,bandStartPxl,0,width,this.height/8,this.height/16);
			this.ctx.fill();
			this.ctx.fillStyle="rgba(26,115,232,0.2)"
			this.roundRect( this.ctx,bandStartPxl,this.height*2/8,width,this.height*4/8,this.height/16);
			this.ctx.fill();
			this.ctx.fillStyle=currentRegionFillColor;
			this.roundRect( this.ctx,bandStartPxl,this.height*7/8,width,this.height/8,this.height/16);
			this.ctx.fill();
			//this.ctx.stroke();

  }

	private roundRect(context,x, y, w, h, radius)
	{
	  let r = x + w;
	  let b = y + h;
	  context.beginPath();
	  context.moveTo(x+radius, y);
	  context.lineTo(r-radius, y);
	  context.quadraticCurveTo(r, y, r, y+radius);
	  context.lineTo(r, y+h-radius);
	  context.quadraticCurveTo(r, b, r-radius, b);
	  context.lineTo(x+radius, b);
	  context.quadraticCurveTo(x, b, x, b-radius);
	  context.lineTo(x, y+radius);
	  context.quadraticCurveTo(x, y, x+radius, y);
	  //context.stroke();
	}
}
