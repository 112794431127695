import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Chromosome } from '../data/chromosome'
import { tap } from 'rxjs/operators';
import { Importer } from '../datahandler/importer'
import { BrowserService } from './browser.service'
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private httpClient: HttpClient,private engine:BrowserService) {
		console.log("Main Data Service Started")
	}
	public importFile(file:File,tracks) {
		let importer:Importer=new Importer(tracks);
		importer.importFromFile(file,this.engine)
	}

}
