import { Injectable, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Chromosome } from '../data/chromosome'
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  @Output() mover: EventEmitter<any> = new EventEmitter();
	public chr:string="1";
  public beg:number=1;
  public end:number=1;
	public dataLoaded:boolean=false;
  public drawerWidth:number=0;
	public chromosomes={"hg18":[],"hg19":[],"hg38":[]};
	public currentChromosome:Chromosome;
	public currentGenome="hg19";
	public tracks=[];

	//graphics
	public gridColor="rgba(240, 240, 240,1)"
	public gridLineWidth=0.5;
	public baseColors={"A":"#2196F3","G":"#4CAF50","T":"#FFEB3B","C":"#F44336","N":"#EEEEEE"}
	public leftSideBar:boolean=true;
	public jupyter=false;

  constructor(private httpClient:HttpClient) {
		this.loadReference();
	}

	public setPosition(chr:string,start:number,end:number) {
		console.log(start,end,chr)
    this.chr=""+chr;
		for(let chrom in this.chromosomes[this.currentGenome]) {
			let chromosome:Chromosome=this.chromosomes[this.currentGenome][chrom]
			if(chromosome.name==this.chr) {
				this.currentChromosome=chromosome;
				this.end=(end==1)?this.currentChromosome.length:end;
				this.beg=start;
				setTimeout(()=>{this.move(0)},100)

			}
		}
    this.getGenomeData(this.currentChromosome);
  }

	public setChr(chr:string) {
    this.chr=""+chr;
		for(let chrom in this.chromosomes[this.currentGenome]) {
			let chromosome:Chromosome=this.chromosomes[this.currentGenome][chrom]
			if(chromosome.name==this.chr) {
				this.currentChromosome=chromosome;
				this.end=this.currentChromosome.length;
				this.beg=1;
				setTimeout(()=>{this.move(0)},100)

			}
		}
    this.getGenomeData(this.currentChromosome);
  }

  public getGenomeData(chr:Chromosome) {
		this.getGenes("hg19",chr).subscribe((res)=>{
				chr.genes=res;
				this.updateViewData();
				this.move(0);
				console.log(chr)
		});

  }

	public loadReference() {
		this.getChromosomes("hg19").subscribe((res)=>{
				this.chromosomes["hg19"]=res;
				console.log(this.chromosomes);
				this.setPosition(this.chr,this.beg,this.end)
		});

	}
	public getChromosomes(version:string) {
		return this.httpClient.get<Chromosome[]>('assets/hg19/hg19.json');
	}

	public getGenes(version:string,chr:Chromosome) {
		return this.httpClient.get<Chromosome[]>(`assets/hg19/genes_chr${chr.name}.json`);
	}


	public buffer_chr="";
	public buffer_beg=0;
	public buffer_end=0;
	public buffer_string="";
	public buffer_loading=false;

	public checkSeq() {
		if(this.currentChromosome.name!=this.buffer_chr) {
			this.resetBuffer();
		}
		if(this.ppb()>0.01) {
			let border_beg=this.beg-1000;
			if(border_beg<1) {border_beg=1;}
			let border_end=this.end+1000;
			if((this.buffer_beg>border_beg || this.buffer_end<border_end) && this.buffer_loading==false) {
				this.buffer_loading=true;
				let buffer_border_beg=this.beg-50000;
				if(buffer_border_beg<1) {buffer_border_beg=1;}
				let buffer_border_end=this.end+50000;
				this.getSeq(this.currentChromosome.name,buffer_border_beg,buffer_border_end).subscribe((res:any)=>{
						this.buffer_beg=buffer_border_beg;
						this.buffer_end=buffer_border_end;
						this.buffer_string=res.sequence.toUpperCase(); 
						this.buffer_loading=false;
						this.move(0);
				});
			}
		}
	}

	public getBaseAt(pos) {
		if(this.currentChromosome.name==this.buffer_chr && this.buffer_beg<=pos && this.buffer_end>pos) {
			return this.buffer_string[pos-this.buffer_beg];
		} else {
			return "N"
		}
	}

	private resetBuffer() {
		this.buffer_chr=this.currentChromosome.name;
		this.buffer_beg=0;
		this.buffer_end=0;
		this.buffer_string="";
		this.buffer_loading=false;
	}

	public getSeq(chr:string,beg:number,end:number) {
		return this.httpClient.get<string>(`${environment.apiEndpoint}sequence/?genome=hg19&chr=${chr}&start=${beg}&end=${end}`);
	}


	public updateViewData() {
		//setVariantstoRender
  }

  public getBaseWidth():number {
    if(this.currentChromosome) {
      if( this.end-this.beg>this.currentChromosome.length*1) {
        return this.currentChromosome.length*1;
      }
    }
    return this.end-this.beg;
  }

  public zoomIn(scale=1) {
    let screenBases=this.getBaseWidth();

    if(screenBases>10 && Math.round(this.end-scale*screenBases/4)-Math.round(this.beg+scale*screenBases/4)>10) {
      this.beg=Math.round(this.beg+scale*screenBases/4);
      this.end=Math.round(this.end-scale*screenBases/4);
      if(this.beg<1) { this.beg=1;this.end=this.beg+this.getBaseWidth();}
      this.move(0);
    }
  }
  public zoomOut(scale=1) {
    let screenBases=this.getBaseWidth();
    if(screenBases<100000000000) {
      this.beg=Math.round(this.beg-scale*screenBases/4);
      this.end=Math.round(this.end+scale*screenBases/4);
      if(this.beg<1) { this.beg=1;this.end=this.beg+this.getBaseWidth();}
			if(this.end<1) {}
      if(this.end>this.currentChromosome.length) { this.end=this.currentChromosome.length }

      this.move(0);
    }
  }

  public move(move:number) {

    let screenBases=Math.round(this.getBaseWidth());
    let bases=Math.round(move/this.getTrackWidthPixels()*screenBases);
    this.beg=this.beg-bases;
    if(this.beg<1) { this.beg=1;}

    this.end=this.beg+screenBases;
    if(this.beg<1) { this.beg=1;this.end=this.beg+this.getBaseWidth();}
    if(this.end>this.currentChromosome.length*1) { this.end=this.currentChromosome.length*1;this.beg=this.end-screenBases; }

    if(move!=0) {
      this.updateViewData();
    }
		this.mover.emit(this.beg);
		this.checkSeq();
    //console.log(this.beg,this.end,bases);
  }

  public getLefty():number {
    let left=100;//trackbar;
    return left;
  }

	public toggleSideBar() {
		this.leftSideBar=!this.leftSideBar;
		this.move(0);
	}

  public getTrackWidthPixels():number {
		if(this.leftSideBar) {
			return window.innerWidth-220-80;
		} else {
			return window.innerWidth-80;
		}

  }

  public scale(scale:number,x:number) {
    if(scale<0) {
      scale=scale*3;
    }
    let ww=this.getBaseWidth();
    let xx=this.getPositionInBases(x);
    let left=(xx-this.beg)*1/(1+scale);
    let right=(this.end-xx)*1/(1+scale);

    this.beg=Math.round(xx-left);
    this.end=Math.round(xx+right);
    this.updateViewData();
    this.move(0);
  }

  public ppb() {
    return this.getTrackWidthPixels()/this.getBaseWidth();
  }
  public zoomInOn(x:number) {
    this.scale(1.5,x);
  }
  public centerOn(xx:number) {
    console.log("centerOn "+xx);
    let w=this.end-this.beg;
    let left=(xx-w/2);
    let right=(xx+w/2);

    this.beg=Math.round(left);
    this.end=Math.round(right);
    this.updateViewData();
    this.move(0);
  }

  public getPositionInBases(x:number):number {
    let left=100;//trackbar;
    let xx=x-left;
    let pos=this.beg+this.getBaseWidth()/this.getTrackWidthPixels()*xx;
    return pos;
  }

  public getGridStart():number {
    let partSize=this.getPartitionSize();
    let startP=this.beg%partSize;
    return this.beg-startP;
  }
  public getPartitionSize():number {
    let totalSize=this.end-this.beg;
    for(let a=10;a>0;a--) {
      if(Math.pow(10,a)<totalSize/2) {
        return Math.pow(10,a);
      }
    }
    return 1;
  }
}
