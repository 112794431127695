import { Component, OnInit,Input,ViewChild,ElementRef } from '@angular/core';
import { Track } from '../../../core/data/track'
import { Variants } from '../../../core/data/variants'
import { BrowserService } from '../../../core/services/browser.service'

const genecolor="#29434e"

@Component({
  selector: 'app-gene-track',
  templateUrl: './genetrack.component.html',
  styleUrls: ['./genetrack.component.scss']
})


export class GeneTrackComponent implements OnInit {

  @ViewChild('c') c:ElementRef ;
	@Input() strand:any;
	public height=50;
	public subscription;
  public ctx:CanvasRenderingContext2D;

  constructor(private engine:BrowserService) { }

  ngOnInit() {
		this.subscription = this.engine.mover.subscribe(item => this.reDraw());
		this.ctx=(<HTMLCanvasElement>this.c.nativeElement).getContext('2d');
		this.ctx.font = "11px Helvetica";
  }

	public reDraw() {
    (<HTMLCanvasElement>this.c.nativeElement).width=this.engine.getTrackWidthPixels();
    (<HTMLCanvasElement>this.c.nativeElement).height=this.height;
    this.drawGrid();
    this.drawData();
  }

	public drawData() {
		if(this.engine.currentChromosome.genes) {
			let genes=this.engine.currentChromosome.genes;
			let count=genes.length;
			for(let b in genes) {
				//check if gene in window
				if(genes[b][5]>this.engine.beg && genes[b][4]<this.engine.end) {
						if(genes[b][6]*1==this.strand*1) {
							this.drawGene(genes[b])
						}

				}
			}
		}
	}

	public drawGene(gene) {
		let beg=this.engine.beg;
		let end=this.engine.end;
		let gene_beg=gene[4]
		let gene_end=gene[5]
		let strand=gene[6]
		let x=0,y=0;
		let width=this.engine.getTrackWidthPixels();
		let ctx=this.ctx;
		let height=this.height;
		let curve=this.height/3;

		let oriStartPxl=x+(gene_beg*1-beg)/(end-beg)*width;
		let oriEndPxl=x+(gene_end*1-beg)/(end-beg)*width;
		if(oriStartPxl<0) { oriStartPxl=0 }
		if(oriEndPxl>width) { oriEndPxl=width }
		if(curve*5>oriEndPxl-oriStartPxl) { curve=(oriEndPxl-oriStartPxl)/5 }
		this.ctx.fillStyle=genecolor;
		this.ctx.beginPath()
		if(strand==0) {
			this.ctx.moveTo(oriStartPxl,y+2);
			this.ctx.lineTo(oriEndPxl-curve,y+2);
			this.ctx.quadraticCurveTo(oriEndPxl, y+this.height/2, oriEndPxl-curve,y+this.height-2);
			this.ctx.lineTo(oriStartPxl,y+this.height-2);
			this.ctx.lineTo(oriStartPxl,y+2);
		} else {
			this.ctx.moveTo(oriEndPxl,y+2);
			this.ctx.lineTo(oriStartPxl+curve,y+2);
			this.ctx.quadraticCurveTo(oriStartPxl, y+this.height/2, oriStartPxl+curve,y+this.height-2);
			this.ctx.lineTo(oriEndPxl,y+this.height-2);
			this.ctx.lineTo(oriEndPxl,y+2);
		}
		this.ctx.fill()
		this.ctx.closePath()
		if(oriEndPxl-oriStartPxl>80) {
			ctx.font = "12px Roboto";
			ctx.textAlign="center";
			ctx.fillStyle ="#FFFFFF";
			ctx.fillText(gene[1],oriStartPxl+(oriEndPxl-oriStartPxl)/2,y+height/2+2);

		}
	}

	public drawGrid() {
    let w=(<HTMLCanvasElement>this.c.nativeElement).width;
    let h=(<HTMLCanvasElement>this.c.nativeElement).height;
    let partSize=this.engine.getPartitionSize();
    let ppb:number=w/this.engine.getBaseWidth();
    for(let p=this.engine.getGridStart();p<this.engine.end*1;p+=partSize) {
      this.ctx.strokeStyle=this.engine.gridColor;
      this.ctx.beginPath();
      let x=ppb*(p-this.engine.beg*1);
      this.ctx.moveTo(x,0);
      this.ctx.lineTo(x,this.height);
      this.ctx.stroke();
    }

  }
}
