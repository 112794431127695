import { Variants } from '../data/variants';
import { BrowserService } from '../services/browser.service'
export class Importer {
	private tracks;
	private engine:BrowserService

	constructor(tracks) {
		this.tracks=tracks;
	}

	public importFromFile(file,engine:BrowserService) {
		this.engine=engine;
		let name=file.name.split(".")[0]
		let genome:Variants= new Variants(name);
    genome.type=(Math.random()>0.5)?"ancestry":"23andMe";
    var reader = new FileReader();
      reader.addEventListener("load", () => {
        // Entire file
        //if(genome.type=="23andMe") {
          var lines:string[]=(<string>reader.result).split("\n");
          this.parse23AndMe(lines,0,genome);
        //}

      });
      //console.log("Reading File");
      reader.readAsText(file);

      //this.cdRef.detectChanges();
  }

	public parse23AndMe(lines:string[],pos,genome:Variants) {
		for(let a=pos;a<lines.length;a++) {
			if(lines[a][0]!="#") {
				// rsid	chromosome	position	genotype
				let fields=lines[a].split("\t");
				let snp_name=fields[0];
				let chr=fields[1];
				let position:number= +fields[2];
				let posbin=Math.trunc((position/Variants.partitionSize));
				let genotype=fields[3]
				if(fields.length==5) {
					genotype+=fields[4]
					genome.type="ancestry"
				}
				let variant={"snp":snp_name,"pos":position,"geno":genotype}
				if(!(chr in genome.data)) {
					genome.data[chr]={}
				}
				if(!(posbin in genome.data[chr])) {
					genome.data[chr][posbin]=[]
				}
				genome.data[chr][posbin].push(variant)
			} else {
				//console.log(lines[a])
			}
		}
		console.log("file imported")
		this.tracks.push(genome)
		setTimeout(() => { this.engine.move(0) },100)
		console.log(this.tracks)
	}
}
