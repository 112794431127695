import { Component, OnInit,Input,ViewChild,ElementRef } from '@angular/core';
import { Track } from '../../../core/data/track'
import { Variants } from '../../../core/data/variants'
import { Chromosome } from '../../../core/data/chromosome'
import { BrowserService } from '../../../core/services/browser.service'

const color={
  "gneg":"rgba(230,230,230,1)",
  "gpos25":"rgba(191,191,191,1)",
  "gpos50":"rgba(127,127,127,1)",
  "gpos75":"rgba(64,64,64,1)",
  "gpos100":"rgba(0,0,0,1)",
  "gpos":"rgba(200,180,150,1)",
  "acen":"rgba(200,127,0,1)",
  "gvar":"rgba(127,127,127,1)",
  "stalk":"rgba(100,100,100,1)"
}
const revComp={"A":"T","C":"G","G":"C","T":"A","N":"N"}
const baseHeight={"A":0.55,"C":0.45,"G":0.55,"T":0.45,"N":0.5}
const strokeStyle="rgba(60,60,60,0.2)"
const lineWidth=0.1

@Component({
  selector: 'app-main-track',
  templateUrl: './maintrack.component.html',
  styleUrls: ['./maintrack.component.scss']
})


export class MainTrackComponent implements OnInit {

  @ViewChild('c') c:ElementRef ;
	public height=100;
	public subscription;
  public ctx:CanvasRenderingContext2D;
	public buffer:any={beg:0,end:0,chr:"0",bases:[]};
	public zoomlevels={chrom:5000000,chromatin:1000,histon:10000,bases:1}
	public chromosome:Chromosome;

  constructor(private engine:BrowserService) { }

  ngOnInit() {
		this.subscription = this.engine.mover.subscribe(item => this.reDraw());
		this.ctx=(<HTMLCanvasElement>this.c.nativeElement).getContext('2d');
		this.ctx.font = "11px Helvetica";
  }

	public reDraw() {
		this.chromosome=this.engine.currentChromosome;
    (<HTMLCanvasElement>this.c.nativeElement).width=this.engine.getTrackWidthPixels();
    (<HTMLCanvasElement>this.c.nativeElement).height=this.height;
		if(this.engine.getBaseWidth()>this.zoomlevels.chrom) {
			this.drawChrom();
		} else if (this.engine.getBaseWidth()>this.zoomlevels.chromatin) {
			this.drawChromatin();
		} else {
			this.drawBases()
		}

  }

	public drawBases() {
		for(let p=this.engine.beg;p<this.engine.end;p++) {
				let base=this.engine.getBaseAt(p);
				let w=(<HTMLCanvasElement>this.c.nativeElement).width;
				if(p>this.engine.beg*1 && p<=this.engine.end*1) {
					let x=this.engine.ppb()*(p-this.engine.beg*1);
					let ppb=this.engine.ppb();
					if(ppb<1) { ppb=1 }
					if(base!="") {
						if(base=="C" || base=="G") {
							if(ppb>5) {
								this.ctx.strokeStyle ="#000000";
								this.ctx.lineWidth=ppb/8;
								this.ctx.beginPath();
								this.ctx.moveTo(x+ppb/4,4);
								this.ctx.lineTo(x+ppb/4,this.height-4);
								this.ctx.moveTo(x+ppb*2/4,4);
								this.ctx.lineTo(x+ppb*2/4,this.height-4);
								this.ctx.moveTo(x+ppb*3/4,4);
								this.ctx.lineTo(x+ppb*3/4,this.height-4);
								this.ctx.closePath();
								this.ctx.stroke();

							}
						}
						if(base=="T" || base=="A") {
							if(ppb>5) {
								this.ctx.strokeStyle ="#000000";
								this.ctx.lineWidth=ppb/8;
								this.ctx.beginPath();
								this.ctx.moveTo(x+ppb/3,4);
								this.ctx.lineTo(x+ppb/3,this.height-4);
								this.ctx.moveTo(x+ppb*2/3,4);
								this.ctx.lineTo(x+ppb*2/3,this.height-4);
								this.ctx.closePath();
								this.ctx.stroke();

							}

						}
						this.ctx.fillStyle=this.engine.baseColors[base]
						//this.ctx.fillRect(x+ppb/10,4,ppb-ppb/5,this.height*baseHeight[base]-8);
						this.drawBase(this.ctx,x+ppb/10,4,ppb-ppb/5,this.height*baseHeight[base]-8,this.ctx.fillStyle,0)
						if(ppb>5) {
							this.ctx.font = "12px Helvetica";
							this.ctx.textAlign="center";
							this.ctx.fillStyle ="#000000";
							this.ctx.fillText(base,x+ppb/2,this.height/4+6,);
						}
					}

					if(1) {
						this.ctx.fillStyle=this.engine.baseColors[revComp[base]]
						//this.ctx.fillRect(x+ppb/10,this.height*baseHeight[base]+4,ppb-ppb/5,this.height*baseHeight[revComp[base]]-8);
						this.drawBase(this.ctx,x+ppb/10,this.height*baseHeight[base]+4,ppb-ppb/5,this.height*baseHeight[revComp[base]]-8,this.ctx.fillStyle,1)
						if(ppb>5) {
							this.ctx.font = "12px Helvetica";
							this.ctx.textAlign="center";
							this.ctx.fillStyle ="#000000";
							this.ctx.fillText(revComp[base],x+ppb/2,this.height*3/4+6,ppb);
						}
					}
				}
			}
			this.ctx.strokeStyle ="#000000";
			this.ctx.lineWidth=4;
			this.ctx.beginPath();
			this.ctx.moveTo(0,2);
			this.ctx.lineTo(this.engine.getTrackWidthPixels(),2);
			this.ctx.moveTo(0,this.height-2);
			this.ctx.lineTo(this.engine.getTrackWidthPixels(),this.height-2);
			this.ctx.closePath();
			this.ctx.stroke();
	}

	public drawBase(ctx:CanvasRenderingContext2D,x:number,y:number,w:number,h:number,color,reverse) {
		ctx.fillStyle=color;

		if(reverse==0) {
			this.ctx.beginPath();
			this.ctx.moveTo(x,y);
			this.ctx.lineTo(x+w,y);
			this.ctx.lineTo(x+w,y+h/8*7);
			this.ctx.lineTo(x,y+h/8*7);
			this.ctx.lineTo(x,y);
			this.ctx.fill();
			this.ctx.closePath();
			this.ctx.beginPath();
			ctx.ellipse(x+w/2, y+h/8*7, w/2,h/8, 0,0, 2*Math.PI);
			this.ctx.fill();
			this.ctx.closePath();
		}
		if(reverse==1) {
			this.ctx.beginPath();
			this.ctx.moveTo(x,y+h/8);
			this.ctx.lineTo(x+w,y+h/8);
			this.ctx.lineTo(x+w,y+h);
			this.ctx.lineTo(x,y+h);
			this.ctx.lineTo(x,y+h/8);
			this.ctx.fill();
			this.ctx.closePath();
			this.ctx.beginPath();
			ctx.ellipse(x+w/2, y+h/8, w/2,h/8, 0,0, 2*Math.PI);
			this.ctx.fill();
			this.ctx.closePath();
		}
	}

	public drawChromatin() {
		let width=this.engine.getTrackWidthPixels();
		let x=0,y=0;
		let ctx=this.ctx;
		let height=this.height;
		let loopwidth=this.engine.ppb()*5000;
		let extra=this.engine.beg*this.engine.ppb()%loopwidth
		ctx.strokeStyle="#333333";
		ctx.lineWidth=this.engine.ppb()*height;
		ctx.fillStyle="rgba(191,191,191,1)";
		for(let i=x-extra;i<width;i+=loopwidth) {
			if (this.engine.getBaseWidth()>this.zoomlevels.histon) {
				ctx.beginPath();
				ctx.ellipse(i+loopwidth/2, height*3/4, loopwidth*3.5/16, height*3.5/16, 0, 0, 2 * Math.PI);
				ctx.fill();
				ctx.stroke();
				ctx.closePath();
				ctx.beginPath();
				ctx.ellipse(i+loopwidth*4/4, height*1/4, loopwidth*3.5/16, height*3.5/16, 0, 0, 2 * Math.PI);
				ctx.fill();
				ctx.stroke();
				ctx.closePath();
			}
				ctx.beginPath();
				ctx.moveTo(i,0);
				ctx.quadraticCurveTo(i+loopwidth/4,0, i+loopwidth/4,height/2);
				ctx.quadraticCurveTo(i+loopwidth/4,height, i+loopwidth/2,height);
				ctx.quadraticCurveTo(i+loopwidth*3/4,height, i+loopwidth*3/4,height/2);
				ctx.quadraticCurveTo(i+3*loopwidth/4,0, i+loopwidth,0);
				ctx.stroke();
				ctx.closePath();




		}

	}

	public drawChrom() {
		let centro=0;
		let beg=this.engine.beg;
		let end=this.engine.end;
		let x=0,y=0;
		let width=this.engine.getTrackWidthPixels();
		let ctx=this.ctx;
		let height=this.height;

		for(let i in this.chromosome.idiogram) {
			let band:any=this.chromosome.idiogram[i];
			if(band.color=="acen") { centro++ }
			let bandColor:string=color[band.color];
			if(!bandColor) { bandColor="#990000";console.log(band.color)}
			ctx.fillStyle=bandColor;
			ctx.strokeStyle=bandColor;
			if(band.beg*1>this.engine.end || band.end*1<this.engine.beg) {
				//band out of scope
			} else {


				let bandStart=band.beg*1;
				if(bandStart<beg) { bandStart=beg; }
				let bandEnd=band.end*1;
				if(bandEnd>end) { bandEnd=end; }
				let bandStartPxl=x+(bandStart-beg)/(end-beg)*width;
				let bandEndPxl=x+(bandEnd-beg)/(end-beg)*width;
				let drawChrShape:boolean=(band.end*1-band.beg*1)<(end-beg)*2;
				if(band.beg*1==0 && drawChrShape) {
					//start
					let oribandStartPxl=x+(band.beg*1-beg)/(end-beg)*width;
					let oribandEndPxl=x+(band.end*1-beg)/(end-beg)*width;
					ctx.save();
					ctx.beginPath();
					ctx.moveTo(oribandEndPxl,y+height);
					ctx.quadraticCurveTo(oribandStartPxl,y+height,oribandStartPxl,y+height/2);
					ctx.quadraticCurveTo(oribandStartPxl,y,oribandEndPxl,y);
					ctx.lineTo(oribandEndPxl,y+height);
					ctx.stroke();
					ctx.clip();
					ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
					ctx.restore();
				} else if(Number(i)==this.chromosome.idiogram.length-1 && drawChrShape) {
					//end
					let oribandStartPxl=x+(band.beg*1-beg)/(end-beg)*width;
					let oribandEndPxl=x+(band.end*1-beg)/(end-beg)*width;
					ctx.save();
					ctx.beginPath();
					ctx.moveTo(oribandStartPxl,y+height);
					ctx.quadraticCurveTo(oribandEndPxl,y+height,oribandEndPxl,y+height/2);
					ctx.quadraticCurveTo(oribandEndPxl,y,oribandStartPxl,y);
					ctx.lineTo(oribandStartPxl,y+height);
					ctx.stroke();
					ctx.clip();
					ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
					ctx.restore();
				} else if(band.color=="acen" && drawChrShape) {
					//centromeer

					ctx.save();
					ctx.beginPath();
					let oribandStartPxl=x+(band.beg*1-beg)/(end-beg)*width;
					let oribandEndPxl=x+(band.end*1-beg)/(end-beg)*width;
					let bandwidth=oribandEndPxl-oribandStartPxl;
					if(centro==1) {
						//band before centro
						ctx.moveTo(oribandStartPxl,y+height);
						ctx.quadraticCurveTo(oribandEndPxl,y+height,oribandEndPxl,y+height/2);
						ctx.quadraticCurveTo(oribandEndPxl,y,oribandStartPxl,y);
						ctx.lineTo(oribandStartPxl,y+height);
					} else {
						//band after centro
						ctx.moveTo(oribandEndPxl,y+height);
						ctx.quadraticCurveTo(oribandStartPxl,y+height,oribandStartPxl,y+height/2);
						ctx.quadraticCurveTo(oribandStartPxl,y,oribandEndPxl,y);
						ctx.lineTo(oribandEndPxl,y+height);
					}


					ctx.stroke();
					ctx.clip();
					ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
					ctx.restore();

				} else {
					ctx.save();
					ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
					ctx.beginPath();
					ctx.rect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
					ctx.stroke();
					ctx.restore();
					//ctx.fill();
				}

			}
	}
}

/*

let centro=0;
for(let i in idiogram) {
	let band:any=idiogram[i];

	if(band.color=="acen") { centro++ }
	let bandColor:string=color[band.color];
	if(!bandColor) { bandColor="#990000";console.log(band.color)}
	ctx.fillStyle =bandColor;
	ctx.strokeStyle =strokeStyle;
	ctx.lineWidth=lineWidth;
	if(band.beg*1>end || band.end*1<beg) {
		//band out of scope
	} else {
		let bandStart=band.beg*1;
		if(bandStart<beg) { bandStart=beg; }
		let bandEnd=band.end*1;
		if(bandEnd>end) { bandEnd=end; }
		let bandStartPxl=x+(bandStart-beg)/(end-beg)*width;
		let bandEndPxl=x+(bandEnd-beg)/(end-beg)*width;
		let drawChrShape:boolean=(band.end*1-band.beg*1)<(end-beg)*2;
		if(band.beg*1==0 && drawChrShape) {
			//start
			let oribandStartPxl=x+(band.beg*1-beg)/(end-beg)*width;
			let oribandEndPxl=x+(band.end*1-beg)/(end-beg)*width;
			ctx.save();
			ctx.beginPath();
			ctx.moveTo(oribandEndPxl,y+height);
			ctx.quadraticCurveTo(oribandStartPxl,y+height,oribandStartPxl,y+height/2);
			ctx.quadraticCurveTo(oribandStartPxl,y,oribandEndPxl,y);
			ctx.lineTo(oribandEndPxl,y+height);
			ctx.stroke();
			ctx.clip();
			ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
			ctx.restore();
		} else if(Number(i)==idiogram.length-1 && drawChrShape) {
			//end
			let oribandStartPxl=x+(band.beg*1-beg)/(end-beg)*width;
			let oribandEndPxl=x+(band.end*1-beg)/(end-beg)*width;
			ctx.save();
			ctx.beginPath();
			ctx.moveTo(oribandStartPxl,y+height);
			ctx.quadraticCurveTo(oribandEndPxl,y+height,oribandEndPxl,y+height/2);
			ctx.quadraticCurveTo(oribandEndPxl,y,oribandStartPxl,y);
			ctx.lineTo(oribandStartPxl,y+height);
			ctx.stroke();
			ctx.clip();
			ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
			ctx.restore();
		} else if(band.color=="acen" && drawChrShape) {
			//centromeer

			ctx.save();
			ctx.beginPath();
			let oribandStartPxl=x+(band.beg*1-beg)/(end-beg)*width;
			let oribandEndPxl=x+(band.end*1-beg)/(end-beg)*width;
			let bandwidth=oribandEndPxl-oribandStartPxl;
			if(centro==1) {
				//band before centro
				ctx.moveTo(oribandStartPxl,y+height);
				ctx.quadraticCurveTo(oribandEndPxl,y+height,oribandEndPxl,y+height/2);
				ctx.quadraticCurveTo(oribandEndPxl,y,oribandStartPxl,y);
				ctx.lineTo(oribandStartPxl,y+height);
			} else {
				//band after centro
				ctx.moveTo(oribandEndPxl,y+height);
				ctx.quadraticCurveTo(oribandStartPxl,y+height,oribandStartPxl,y+height/2);
				ctx.quadraticCurveTo(oribandStartPxl,y,oribandEndPxl,y);
				ctx.lineTo(oribandEndPxl,y+height);
			}


			ctx.stroke();
			ctx.clip();
			ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
			ctx.restore();

		} else {
			ctx.save();
			ctx.fillRect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
			ctx.beginPath();
			ctx.rect(bandStartPxl,y,bandEndPxl-bandStartPxl,height);
			ctx.stroke();
			ctx.restore();
			//ctx.fill();
		}
		if(bandEndPxl-bandStartPxl>80) {

			ctx.font = "8px Helvetica";
			ctx.textAlign="center";
			ctx.fillStyle ="#999999";
			ctx.fillText(band.loc,bandStartPxl+(bandEndPxl-bandStartPxl)/2,y+height/2+1);
			ctx.fillStyle =bandColor;
		}

		//console.log(bandStartPxl,y,bandEndPxl-bandStartPxl,height,bandColor);
	}

}
}
	public drawData() {
		//this.ctx.font = "10px Helvetica";
		//this.ctx.textAlign="left";
		//this.ctx.fillStyle ="#000000";
		//this.ctx.fillText(this.track.name,3,10);
		if(this.track) {
			this.updateBuffer();
		}
		let count=this.buffer.variants.length
		for(let b in this.buffer.variants) {
			if(!('rand' in this.buffer.variants[b])) {
				this.buffer.variants[b]['rand']=Math.random()
			}

			if(this.buffer.variants[b]['rand']<this.engine.ppb()*10000) {
				let variant=this.buffer.variants[b];
				let w=(<HTMLCanvasElement>this.c.nativeElement).width;
				let p=variant['pos'];
				if(p>this.engine.beg*1 && p<=this.engine.end*1) {
					let x=this.engine.ppb()*(p-this.engine.beg*1);
					let ppb=this.engine.ppb();
					if(ppb<1) { ppb=1 }
					if(variant['geno'].length>0) {
						this.ctx.fillStyle=this.engine.baseColors[variant['geno'][0]]
						this.ctx.fillRect(x,0.5,ppb,this.height/2-1);
						if(ppb>5) {
							this.ctx.font = "12px Helvetica";
							this.ctx.textAlign="center";
							this.ctx.fillStyle ="#000000";
							this.ctx.fillText(variant['geno'][0],x+ppb/2,this.height/4+6,);
						}
					}
					if(variant['geno'].length>1) {
						this.ctx.fillStyle=this.engine.baseColors[variant['geno'][1]]
						this.ctx.fillRect(x,this.height/2+0.5,ppb,this.height/2-1);
						if(ppb>5) {
							this.ctx.font = "12px Helvetica";
							this.ctx.textAlign="center";
							this.ctx.fillStyle ="#000000";
							this.ctx.fillText(variant['geno'][1],x+ppb/2,this.height*3/4+6,ppb);
						}
					}
				}
			}

		}
	}

	public updateBuffer() {

			if(this.buffer.chr==this.engine.currentChromosome.name && this.buffer.beg<=Math.trunc((this.engine.beg/1000000)) && this.buffer.end>=Math.trunc((this.engine.end/1000000))+1) {
				//buffer ok

			} else {
				if(this.engine.ppb()>0.000001) {
					this.buffer.variants=[];
					this.buffer.chr=this.engine.currentChromosome.name;
					this.buffer.beg=Math.trunc((this.engine.beg/Variants.partitionSize))
					this.buffer.end=Math.trunc((this.engine.end/Variants.partitionSize))+1

					for(let p in this.track.data[this.buffer.chr]) {

						if(p>=this.buffer.beg && p<=this.buffer.end) {
							this.buffer.variants=this.buffer.variants.concat(this.track.data[this.buffer.chr][p])
						}
					}
					console.log(this.buffer.beg,this.buffer.end);
					console.log(this.buffer)
				}
		}
	}

	public drawGrid() {
    let w=(<HTMLCanvasElement>this.c.nativeElement).width;
    let h=(<HTMLCanvasElement>this.c.nativeElement).height;
    let partSize=this.engine.getPartitionSize();
    let ppb:number=w/this.engine.getBaseWidth();
    for(let p=this.engine.getGridStart();p<this.engine.end*1;p+=partSize) {
      this.ctx.strokeStyle=this.engine.gridColor;
      this.ctx.beginPath();
      let x=ppb*(p-this.engine.beg*1);
      this.ctx.moveTo(x,0);
      this.ctx.lineTo(x,this.height);
      this.ctx.stroke();
    }

  }
	*/
}
