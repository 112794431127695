import { Component } from '@angular/core';
import { DataService } from '../core/services/data.service'
import { BrowserService } from '../core/services/browser.service'
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'genewall-tensor';
	public topBar=true;
	constructor(private activatedRoute: ActivatedRoute,iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,private dataService: DataService,private engine: BrowserService) {
		this.activatedRoute.queryParams.subscribe(params => {
        engine.jupyter = params['jupyter']=="true";
				if(params['chr']) {
					engine.chr=params['chr']
				}
				if(params['start']) {
					engine.beg=params['start']
				}
				if(params['end']) {
					engine.end=params['end']
				}
				if(engine.jupyter) {
					this.topBar=false;
					engine.leftSideBar=false;
				}
    });
		iconRegistry.addSvgIcon('chromosome',sanitizer.bypassSecurityTrustResourceUrl('assets/svg/chromosome.svg'));
		iconRegistry.addSvgIcon('gene',sanitizer.bypassSecurityTrustResourceUrl('assets/svg/gene.svg'));
		iconRegistry.addSvgIcon('23andMe',sanitizer.bypassSecurityTrustResourceUrl('assets/svg/23andMe_logo.svg'));
		iconRegistry.addSvgIcon('ancestry',sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ancestry.svg'));
		iconRegistry.addSvgIcon('condition',sanitizer.bypassSecurityTrustResourceUrl('assets/svg/condition.svg'));
	}

	onFileChange(event) {
    if(event.target.files && event.target.files.length > 0) {
      let files=event.target.files;
      let file = files[0];
			this.dataService.importFile(file,this.engine.tracks);
      /*
      for(let i=0;i<event.target.files.length;i++) {
        let file = event.target.files[i];
        console.log(file);
        let genome:wbGenome = new wbGenome(this.dataService.reference,this.dataService);
        setTimeout(()=>{    //<<<---    using ()=> syntax
          this.dataService.genomes.push(genome);
          genome.counter=this.dataService.genomes.length-1;
        },10);
        genome.importFromFile(file);
      }*/
    }
  }

}
