import { Component, OnInit,Input,ViewChild,ElementRef } from '@angular/core';
import { Track } from '../../../core/data/track'
import { BrowserService } from '../../../core/services/browser.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	@ViewChild('c') c:ElementRef ;
	@Input() track:Track;
	public height=20;
	public subscription;
  public ctx:CanvasRenderingContext2D;

  constructor(private engine:BrowserService) { }

  ngOnInit() {
		this.subscription = this.engine.mover.subscribe(item => this.reDraw());
		this.ctx=(<HTMLCanvasElement>this.c.nativeElement).getContext('2d');
		this.ctx.font = "11px Helvetica";
  }

	public reDraw() {
		(<HTMLCanvasElement>this.c.nativeElement).width=this.engine.getTrackWidthPixels();
		(<HTMLCanvasElement>this.c.nativeElement).height=this.height;
		this.drawGrid();
	}

	public drawGrid() {
    let w=(<HTMLCanvasElement>this.c.nativeElement).width;
    let h=(<HTMLCanvasElement>this.c.nativeElement).height;
    let partSize=this.engine.getPartitionSize();
    let ppb:number=w/this.engine.getBaseWidth();
    for(let p=this.engine.getGridStart();p<this.engine.end*1;p+=partSize) {
      this.ctx.strokeStyle=this.engine.gridColor;
      //this.ctx.lineWidth=0;
      this.ctx.beginPath();
      let x=ppb*(p-this.engine.beg*1);
      this.ctx.moveTo(x,0);
      this.ctx.lineTo(x,this.height);
      this.ctx.stroke();
			this.ctx.font = "12px Helvetica";
			this.ctx.textAlign="left";
			this.ctx.fillStyle ="#999999";
			this.ctx.fillText(this.toTextPos(p),x+3,10);
    }

  }

	public toTextPos(pos):string {
    let p:string = pos+"";
    if(p.length>6) { if(p.substr(p.length-6,6)=="000000") { p=p.substr(0,p.length-6)+"M"; return p+"b"; }}
    if(p.length>3) { if(p.substr(p.length-3,3)=="000") { p=p.substr(0,p.length-3)+"k"; return p+"b"; }}

    return p+"b";
  }
}
