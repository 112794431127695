import { Component, OnInit,Input,ViewChild,ElementRef } from '@angular/core';
import { Track } from '../../../core/data/track'
import { Variants } from '../../../core/data/variants'
import { BrowserService } from '../../../core/services/browser.service'


@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss']
})


export class TrackComponent implements OnInit {

  @ViewChild('c') c:ElementRef ;
	@Input() track:Variants;
	public height=50;
	public subscription;
  public ctx:CanvasRenderingContext2D;
	public buffer:any={beg:0,end:0,chr:"0",variants:[]};

  constructor(private engine:BrowserService) { }

  ngOnInit() {
		this.subscription = this.engine.mover.subscribe(item => this.reDraw());
		this.ctx=(<HTMLCanvasElement>this.c.nativeElement).getContext('2d');
		this.ctx.font = "11px Helvetica";
  }

	public reDraw() {
    (<HTMLCanvasElement>this.c.nativeElement).width=this.engine.getTrackWidthPixels();
    (<HTMLCanvasElement>this.c.nativeElement).height=this.height;
    this.drawGrid();
    this.drawData();
  }

	public drawData() {
		//this.ctx.font = "10px Helvetica";
		//this.ctx.textAlign="left";
		//this.ctx.fillStyle ="#000000";
		//this.ctx.fillText(this.track.name,3,10);
		if(this.track) {
			this.updateBuffer();
		}
		let count=this.buffer.variants.length
		for(let b in this.buffer.variants) {
			if(!('rand' in this.buffer.variants[b])) {
				this.buffer.variants[b]['rand']=Math.random()
			}

			if(this.buffer.variants[b]['rand']<this.engine.ppb()*10000) {
				let variant=this.buffer.variants[b];
				let w=(<HTMLCanvasElement>this.c.nativeElement).width;
				let p=variant['pos'];
				if(p>this.engine.beg*1 && p<=this.engine.end*1) {
					let x=this.engine.ppb()*(p-this.engine.beg*1);
					let ppb=this.engine.ppb();
					if(ppb<1) { ppb=1 }
					if(variant['geno'].length>0) {
						this.ctx.fillStyle=this.engine.baseColors[variant['geno'][0]]
						this.ctx.fillRect(x,0.5,ppb,this.height/2-1);
						if(ppb>5) {
							this.ctx.font = "12px Helvetica";
							this.ctx.textAlign="center";
							this.ctx.fillStyle ="#000000";
							this.ctx.fillText(variant['geno'][0],x+ppb/2,this.height/4+6,);
						}
					}
					if(variant['geno'].length>1) {
						this.ctx.fillStyle=this.engine.baseColors[variant['geno'][1]]
						this.ctx.fillRect(x,this.height/2+0.5,ppb,this.height/2-1);
						if(ppb>5) {
							this.ctx.font = "12px Helvetica";
							this.ctx.textAlign="center";
							this.ctx.fillStyle ="#000000";
							this.ctx.fillText(variant['geno'][1],x+ppb/2,this.height*3/4+6,ppb);
						}
					}
				}
			}

		}
	}

	public updateBuffer() {

			if(this.buffer.chr==this.engine.currentChromosome.name && this.buffer.beg<=Math.trunc((this.engine.beg/1000000)) && this.buffer.end>=Math.trunc((this.engine.end/1000000))+1) {
				//buffer ok

			} else {
				if(this.engine.ppb()>0.000001) {
					this.buffer.variants=[];
					this.buffer.chr=this.engine.currentChromosome.name;
					this.buffer.beg=Math.trunc((this.engine.beg/Variants.partitionSize))
					this.buffer.end=Math.trunc((this.engine.end/Variants.partitionSize))+1

					for(let p in this.track.data[this.buffer.chr]) {

						if(p>=this.buffer.beg && p<=this.buffer.end) {
							this.buffer.variants=this.buffer.variants.concat(this.track.data[this.buffer.chr][p])
						}
					}
					console.log(this.buffer.beg,this.buffer.end);
					console.log(this.buffer)
				}
		}
	}

	public drawGrid() {
    let w=(<HTMLCanvasElement>this.c.nativeElement).width;
    let h=(<HTMLCanvasElement>this.c.nativeElement).height;
    let partSize=this.engine.getPartitionSize();
    let ppb:number=w/this.engine.getBaseWidth();
    for(let p=this.engine.getGridStart();p<this.engine.end*1;p+=partSize) {
      this.ctx.strokeStyle=this.engine.gridColor;
      this.ctx.beginPath();
      let x=ppb*(p-this.engine.beg*1);
      this.ctx.moveTo(x,0);
      this.ctx.lineTo(x,this.height);
      this.ctx.stroke();
    }

  }
}
