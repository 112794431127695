import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './modules/app-routing.module';
import { AppComponent } from './modules/app.component';
import { MatBadgeModule,MatInputModule,MatFormFieldModule,MatExpansionModule,MatTooltipModule,MatToolbarModule,MatSidenavModule,MatIconModule,MatButtonModule,MatDividerModule,MatListModule,MatMenuModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { BrowserComponent } from './modules/browser/browser/browser.component';
import { TrackComponent } from './modules/browser/track/track.component';
import { GeneTrackComponent } from './modules/browser/genetrack/genetrack.component';
import { MainTrackComponent } from './modules/browser/maintrack/maintrack.component';
import { WbStaticChromosomeComponent } from './modules/browser/wb-static-chromosome/wb-static-chromosome.component';
import { WbChromosomeComponent } from './modules/browser/wb-chromosome/wb-chromosome.component';
import { HeaderComponent } from './modules/browser/header/header.component';
import { FiledropComponent } from './modules/filedrop/filedrop.component';

@NgModule({
  declarations: [
    AppComponent,
    BrowserComponent,
    TrackComponent,
		MainTrackComponent,
    WbStaticChromosomeComponent,
    WbChromosomeComponent,
    HeaderComponent,
		GeneTrackComponent,
    FiledropComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
		BrowserAnimationsModule,
		MatToolbarModule,
		MatSidenavModule,
		HttpClientModule,
		MatIconModule,
		MatButtonModule,
		MatDividerModule,
		MatListModule,
		MatMenuModule,
		MatTooltipModule,
		MatExpansionModule,
		MatInputModule,
		MatFormFieldModule,
		MatBadgeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
