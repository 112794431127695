import * as Hammer from 'hammerjs';
import { Component, OnInit,Input,ViewChild,ElementRef } from '@angular/core';
import { BrowserService } from '../../../core/services/browser.service'
import { wbIdiogram } from '../../../core/data/graphics/wb-idiogram'
import { NgZone } from '@angular/core'
import { Chromosome } from '../../../core/data/chromosome'
const lineColor="#990000";
const fillColor="rgba(120,0,0,0.5)";
const gridLineWidth=0.5;

@Component({
  selector: 'app-wb-static-chromosome',
  templateUrl: './wb-static-chromosome.component.html',
  styleUrls: ['./wb-static-chromosome.component.scss']
})
export class WbStaticChromosomeComponent implements OnInit {

	@Input() chromosome:Chromosome;
  @ViewChild('c') c:ElementRef ;
  @Input() height=50;
  @Input() width=200;
	@Input() maxwidth=200;
	@Input() margin=10;
  private zone:NgZone;
  public chrLength:number=1;
  public subscription;
  public ctx:CanvasRenderingContext2D;
  private deltaX:number=0;

  constructor(private engine:BrowserService) { }

  ngOnInit() {
		this.ctx=(<HTMLCanvasElement>this.c.nativeElement).getContext('2d');
		this.reDraw();
  }

	public reDraw() {
		if(this.chromosome) {
      this.width=this.maxwidth*(this.chromosome.length*1)/(this.engine.chromosomes[this.engine.currentGenome][0].length*1);
      (<HTMLCanvasElement>this.c.nativeElement).style.width=this.width+"px";
      (<HTMLCanvasElement>this.c.nativeElement).width=this.width;
      (<HTMLCanvasElement>this.c.nativeElement).height=this.height;
      this.chrLength=this.chromosome.length*1;
      this.drawData();
		}

  }

  public drawData() {
    wbIdiogram.drawIdiogram(this.ctx,this.chromosome.idiogram,1,this.chrLength,this.margin,10,(<HTMLCanvasElement>this.c.nativeElement).width-this.margin*2,(<HTMLCanvasElement>this.c.nativeElement).height-20);
  }

}
