import { Component, OnInit,Input,ViewChild,ElementRef } from '@angular/core';
import { BrowserService } from '../../../core/services/browser.service'
import { MatTooltip } from '@angular/material';
@Component({
  selector: 'app-browser',
  templateUrl: './browser.component.html',
  styleUrls: ['./browser.component.scss']
})
export class BrowserComponent implements OnInit {
	@ViewChild('browser') browser:ElementRef ;

  constructor(public engine:BrowserService) { }

  ngOnInit() {

  }

	public onwheel(e:any) {
		e.preventDefault();
		if(e.deltaY>0) { this.engine.zoomOut(e.deltaY/50)}
		if(e.deltaY<0) { this.engine.zoomIn(-e.deltaY/50)}
		if(e.deltaX>0) { this.engine.move(e.deltaX)}
		if(e.deltaX<0) { this.engine.move(e.deltaX)}
	};

}
